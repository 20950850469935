import React, { useState } from 'react';
import { Analytics } from '@vercel/analytics/react';

import './App.css';

const MAX_ASKS = 15;

function App() {
  const [status, setStatus] = useState('asking');
  const [noClickCount, setNoClickCount] = useState(0);
  const [noButtonText, setNoButtonText] = useState('No');
  const [yesButtonFontSize, setYesButtonFontSize] = useState(16); // Initialize font size state}

  const handleYesClick = () => {
    setStatus('accepted');
  };

  const handleNoClick = () => {
    const newCount = noClickCount + 1;
    setNoClickCount(newCount);

    setYesButtonFontSize(currentSize => currentSize + 5); // Increase font size by 5px

    if (newCount < 10) {
      const responses = [
        'Are you sure?', 'Surely not', 'Think again', "Last chance",
        'Not even a little bit?', 'You might regret this',
        'This could be a mistake', 'Have a heart', 'Is that your final answer?'
      ];
      setNoButtonText(responses[Math.min(newCount, responses.length) - 1]);
    } else {
      setNoButtonText('You\'re breaking my heart 💔');
    }
  };

  const yesButtonStyle = {
    fontSize: `${yesButtonFontSize}px`, // Apply the font size dynamically
  };

  console.log("noClickCount", noClickCount);

  return (
    <div className="App">
      <Analytics />
      <header className="App-header">
        {status === 'asking' ? (
          <>
            <div className="text">Will you be my Valentine?</div>
            <button className="yes-button" style={yesButtonStyle} onClick={handleYesClick}>Yes 🤍</button>
            <button className="no-button" onClick={handleNoClick}>
              {noButtonText}
            </button>
          </>
        ) : (
          <>
            <div className="text">Aww shucks :)</div>
            <div className='text yes-text'>The tale of Sam and Jasmine continues...</div>
          </>
        )}

        <img style={{display: status === 'asking' && noClickCount < 10 ? 'unset' : 'none'}} src="peak.gif" class="bottom-right-img asking-img" />
        <img style={{display: status === 'asking' && noClickCount >= 10 ? 'unset' : 'none'}} src="https://media.tenor.com/pdTtmj53qFYAAAAi/cute-heba.gif" class="bottom-right-img no-img" />
        <img style={{display: status === 'asking' ? 'none' : 'unset'}} src="https://media.tenor.com/z6X7ejA5Vs0AAAAi/peach-goma-love.gif" class="bottom-right-img yes-img" />
      </header>
    </div>
  );
}

export { App };
